
export default {
  name: 'MediSnapCarouselHomePageTitle',

  props: {
    header: {
      type: Object,
      required: true,
    },

    mediclub: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title () {
      return this.header.children.find(item => item?.componentType === 'h1')?.data?.text || ''
    },

    url () {
      return this.header.children.find(item => item?.componentType === 'link')?.data
    },
  },
}
